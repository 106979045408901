:root{  
  --input-font-size: 0.8rem;
  --bs-body-font-size: var(--input-font-size);
  --button-font-size: var(--input-font-size) ;
  --heading-font-size: 0.9rem;
  --heading-font-weight:500;
}

.App {
  text-align: center;
  background-color: #f3f6f8;
  height: 100vh;
}

.App-logo {
  margin-left: 25px;
}


.App-header {
  background-color: #ffffff;
  min-height: 120px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#number-container, #securityCode-container {
  height: 35px;
  padding: 6px 12px !important;
}
#errors-output {
  color: red;
}

input, textarea, select {
  font-size: var(--input-font-size) !important;
}
input:focus,
textarea:focus,
select:focus,
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: inherit !important;
}
#expMonth, #expYear {
  width: 6rem;
}

.h6 {
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight)
}

.btn {
  font-size: var(--button-font-size) !important;
}

.form-group{
  margin-bottom: 0.9rem;
}
